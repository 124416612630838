import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import { isLoggedIn } from "../helpers/userSession"; 

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/BetsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/bets',
    name: 'bets',
    component: () => import('../views/BetsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/bets/:id',
    name: 'bet-view',
    component: () => import('../views/BetView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/my-bets',
    name: 'my-bets',
    component: () => import('../views/MyBetsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/bagres',
    name: 'bagres',
    component: () => import('../views/BagresView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/cassino',
    name: 'cassino',
    component: () => import('../views/Cassino.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/slot-machine',
    name: 'slot-machine',
    component: () => import('../views/SlotMachine.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/coin-flip',
    name: 'coin-flip',
    component: () => import('../views/CoinFlip.vue'),
  },
  {
    path: '/finances',
    name: 'finances',
    component: () => import('../views/FinancesView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/MyProfile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/AdmView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      localStorage.removeItem('accessToken');
      next({ name: 'login' });
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = isLoggedIn();
    if (!isAuthenticated) {
      next({ 'name': 'login' }) 
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router