import { jwtDecode } from 'jwt-decode';

export const isLoggedIn = () => {
    const token = localStorage.getItem('accesToken');
    if (!token) return false;

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (e) {
      console.log(e)
      return false;
    }
}

export const loggout = () => {
    localStorage.removeItem('accessToken')
}

export const logout = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/auth/signin";
}

export const getJWTValue = (key) => {
    const token = localStorage.getItem('accessToken')
    if (!token) return
    const values = jwtDecode(token)
    const value = values[key];
    return value
}
