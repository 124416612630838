<template>
  <Loader :show="loading" />
  <section class="bg-gray-50 min-h-screen flex items-center justify-center">
    <div class="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
      <!-- form -->
      <div class="md:w-1/2 px-8 md:px-16">
        <img class="mx-auto h-50 w-auto" src="../assets/calvobet.png" alt="logo" />
        <div class="flex flex-col gap-4">
          <input v-model="auth.username" class="p-2 mt-8 rounded-xl border" type="email" name="email" placeholder="Usuário">
          <p v-if="v$.username.required.$invalid && submited" class="text-red-500 text-xs italic">Campo necessário</p>
          <div class="relative">
            <input v-model="auth.password" class="p-2 rounded-xl border w-full" type="password" name="password"
              placeholder="Senha">
              <p v-if="v$.password.required.$invalid && submited" class="text-red-500 text-xs italic">Campo necessário</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"
              class="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2" viewBox="0 0 16 16">
              <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>
          </div>
          <button id="loginButton" @keydown.enter="login" @click="login" class="bg-black rounded-xl text-white py-2 hover:scale-105 duration-300">Entrar</button>
        </div>
        <div class="mt-3 text-xs flex justify-between items-center text-[#002D74]">
          <p>Não tem conta?</p>
          <button class="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300">Pede pro
            leozamagro</button>
        </div>
      </div>
      <div class="md:block hidden w-1/2">
        <img class="rounded-2xl" src="../assets/boasvindas.png">
      </div>
    </div>
  </section>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import { reactive, computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { required } from "@vuelidate/validators";
import Loader from '@/components/Loader.vue';
import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const router = useRouter();
const loading = ref(false)

const auth = reactive({
  username: null,
  password: null
})

const rules = computed(() => {
  return {
    username: { required },
    password: { required },
  };
});

const v$ = useVuelidate(rules, auth);

const submited = ref(false)
const login = async () => {
  const result = await v$.value.$validate();
  submited.value = true
  if (!result) return
  let login = new FormData();
  login.append("username", auth.username);
  login.append("password", auth.password);
  loading.value = true
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/users/login`, login);
    if (response.status === 200) {
      localStorage.setItem('accesToken', response.data.access_token)
      router.push({ 'name': 'bets' })
    }
  } catch (error) {
    toast(error.response.data.detail, {
      "type": "info",
    })
  } finally {
    loading.value = false
  }
}

</script>